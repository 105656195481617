<template>
  <div class="d-flex-column align-center main">
    <h3 class="title">Manage Linked Profiles</h3>
    <el-card>
      <!--  START FOR LOOP  -->
      <div v-for="member in members" :key="member._id">
        <div class="d-flex">
          <div class="flex-1 name-wrapper">
            <h4 class="name">
              {{ member.profile.firstName }} {{ member.profile.lastName }}
            </h4>
          </div>
          <div class="flex-0 d-flex-column">
            <el-button
                @click="handleRemoveAccessConfirm(member._id)"
                class="button-thin button-light button-transparent upper bt"
            >
              <span class="light">Unlink</span>
            </el-button>
          </div>
        </div>
        <hr>
      </div>
      <div class="align-text-center mt-2">
        <el-button
            @click="$router.push({name: 'newprofile'})"
            class="button-fat full-width mw-400"
            type="primary">
          Add New Participant
        </el-button>
      </div>
      <!--  END FOR LOOP  -->
    </el-card>
    <br>
    <el-button class="button-fat button-transparent button-light relative full-width mw-400
               clickable"
               @click="resetPassword()"
    >
      Change Password
      <i class="el-icon-link button-last"/>
    </el-button>
  </div>
</template>

<script>

import { mapActions, mapMutations } from 'vuex';
import endpoints, { endpointParams } from '@/utils/constants/api';
import msg from '@/utils/constants/msg';

export default {
  name: 'accountsettings',
  data() {
    return {
      members: [],
      window,
    };
  },
  methods: {
    ...mapActions('views', ['notify']),
    ...mapMutations('root', ['LOADING']),
    resetPassword() {
      const m = process.env.VUE_APP_PIN_ENV === 'test'
        ? 'https://nrl-qa.nrl.digital/account/settings'
        : 'https://www.nrl.com/account/settings';
      window.open(m);
    },
    handleRemoveAccessConfirm(id) {
      this.$confirm('This will unlink the member from your account. Continue?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
      }).then(() => {
        this.handleRemoveAccess(id);
      }).catch(() => { });
    },
    handleRemoveAccess(id) {
      this.LOADING(true);
      // get members
      this.$http.post(endpointParams.profile.unlink(id))
        .then((response) => {
          if (response.data
            && response.data.status
            && (response.data.status === 'success')
          ) {
            this.getMember();
            this.notify({
              msg: msg.success.unlink,
              route: this.$route.name,
              window,
            });
          } else {
            this.notify({
              msg: msg.error.apiError,
              route: this.$route.name,
              type: 'warning',
              window,
            });
          }
          this.LOADING(false);
        })
        .catch((error) => {
          this.notify({
            msg: error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : msg.error.apiError,
            route: this.$route.name,
            type: 'warning',
            window,
          });
          this.LOADING(false);
        });
    },
    getMember() {
      this.$http.get(endpoints.getMembers)
        .then((membersResponse) => {
          if (membersResponse.data
            && membersResponse.data.status
            && (membersResponse.data.status === 'success')
          ) {
            if (membersResponse.data.data) {
              this.$set(this, 'members', membersResponse.data.data);
            }
          } else {
            this.notify({
              msg: msg.error.apiError,
              route: this.$route.name,
              type: 'warning',
              window,
            });
          }
          this.LOADING(false);
        })
        .catch(() => {
          this.notify({
            msg: msg.error.apiError,
            route: this.$route.name,
            type: 'warning',
            window,
          });
          this.LOADING(false);
        });
    },
  },
  mounted() {
    this.getMember();
  },
};
</script>

<style lang="scss" scoped>
  hr {
    border: none;
    border-top: 1px lightgrey solid;
  }

  .title, .name {
    font-family: $fontMedium, Arial;
  }

  .name-wrapper {
    word-wrap: break-word;
    max-width: calc(100% - 119px);
  }

  .bt {
    margin-top: auto;
    margin-bottom: auto;
    letter-spacing: .1em;
  }

  .light {
    color: #505050;
  }

  .upper {
    text-transform: uppercase;
  }

  .relative {
    position: relative;
  }

  .button-last {
    position: absolute;
    right: 10px;
  }
</style>
